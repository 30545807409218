/* styles.css */
.custom-header-height {
    height: 28rem; /* Adjust the height value as needed */
  }
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  .marquee-container {
   
    overflow: hidden;
    white-space: nowrap;
  }
  
  .marquee-content {
    
    animation: marquee 6s linear infinite;
  }
  
  .marquee-content > * {
    flex: 10 10 auto;
  }
  