.customShadow{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* user table */
.userTable {
   @apply bg-white p-4
}
.userTable th{
@apply border text-base font-medium 

} 
.userTable td{
    @apply border text-base text-center
    
    }
/* scroll effect */
.scrollbar-none::-webkit-scrollbar{
display: none;
}

/* Add to your CSS file or within a style tag */
label:hover {
    text-decoration: underline; /* Optional hover effect */
  }
  
  input:focus + label {
    outline: 2px solid blue; /* Focus style */
  }
  
